$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #679e36; //
$COLFLAT2: #679e36; //
$COL3: #ffffff; //
$COLFLAT3: #ffffff; //
$COL4: #2b782c; //
$COLFLAT4: #2b782c; //
$COL5: #ffffff; //
$COLFLAT5: #ffffff; //
$COL6: #e8f9ea; //
$COLFLAT6: #e8f9ea; //
$COL7: #333333; //
$COLFLAT7: #333333; //
$COL8: #333333; //
$COLFLAT8: #333333; //
$COL9: #333333; //
$COLFLAT9: #333333; //
$COL10: #367f2c; //
$COLFLAT10: #367f2c; //
$COL11: #333333; //
$COLFLAT11: #333333; //
$COL12: #333333; //
$COLFLAT12: #333333; //
$COL13: #333333; //
$COLFLAT13: #333333; //
$COL14: #367f2c; //
$COLFLAT14: #367f2c; //
$COL15: #333333; //
$COLFLAT15: #333333; //
$COL16: #ffffff; //
$COLFLAT16: #ffffff; //
$COL17: #333333; //
$COLFLAT17: #333333; //
$COL18: #2a772b; //
$COLFLAT18: #2a772b; //
$COL19: #333333; //
$COLFLAT19: #333333; //
$COL20: rgba(75,142,9,0.8); //green 80%
$COLFLAT20: #568b21; //green 80%
$COL21: rgba(103,158,54,0.8); //grey
$COLFLAT21: #6c9845; //grey
$COL22: rgba(1,98,3,0.8); //block 1
$COLFLAT22: #1a681c; //block 1
$COL23: rgba(131,201,24,0.8); //black
$COLFLAT23: #82ba2d; //black
$COL24: rgba(0,0,0,0.502); //black 50%
$COLFLAT24: #404040; //black 50%
$COL25: #000000; //black
$COLFLAT25: #000000; //black
$COL26: rgba(255,255,255,0.8); //white 80%
$COLFLAT26: #e6e6e6; //white 80%
$COL27: #588f24; //faq
$COLFLAT27: #588f24; //faq
$COL28: #9ca4ac; //Form Field Grey
$COLFLAT28: #9ca4ac; //Form Field Grey
$COL29: #e8e8e8; //light grey
$COLFLAT29: #e8e8e8; //light grey
$COL30: #36529e; //
$COLFLAT30: #36529e; //
$COL31: #ffffff; //
$COLFLAT31: #ffffff; //
$COL32: #ffffff; //
$COLFLAT32: #ffffff; //
$COL33: #c2b86b; //
$COLFLAT33: #c2b86b; //
$COL34: #cccccc; //black
$COLFLAT34: #cccccc; //black
$COL35: #ababab; //black
$COLFLAT35: #ababab; //black
$COL36: rgba(75,142,9,0.8); //head
$COLFLAT36: #568b21; //head
$COL37: #ffffff; //bgwhite
$COLFLAT37: #ffffff; //bgwhite
$COL38: #cccccc; //greyccc
$COLFLAT38: #cccccc; //greyccc
$COL39: #2b782c; //button-color
$COLFLAT39: #2b782c; //button-color
$COL40: #ffffff; //btn-text
$COLFLAT40: #ffffff; //btn-text
$COL41: #156416; //btn-hover-bg
$COLFLAT41: #156416; //btn-hover-bg
$COL42: #156416; //btn-hover-bg
$COLFLAT42: #156416; //btn-hover-bg
$COL43: #cccccc; //case-study-box-border
$COLFLAT43: #cccccc; //case-study-box-border
$COL44: #ffffff; //case-study-box-bg
$COLFLAT44: #ffffff; //case-study-box-bg
$COL45: #83c918; //arrow-hover
$COLFLAT45: #83c918; //arrow-hover
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
