body { background-color: $COL16; }
body { color: $COL17; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL8;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
@media #{$large-up} {
font-size: 29px;

}
}
h2 {
color: $COL9;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL10;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
h4 {
color: $COL11;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL12;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL13;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 25px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 20px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: 800;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL15;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL14;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: $COL18}
a:hover {color: $COL19}
/* Primary:2 */
.MES2 {
background-color: $COL2;
color: $COL3;
 }
/* Primary:3 */
.MES3 {
background-color: $COL2;
color: $COL3;
 }
.MES3 {
background-color: $COL2;
color: $COL3;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL3;
 }
 }
cite.MEC3{
color: $COL3;
}
/* Secondary:4 */
.MES4 {
background-color: $COL4;
color: $COL5;
 }
/* Secondary:5 */
.MES5 {
background-color: $COL4;
color: $COL5;
 }
.MES5 {
background-color: $COL4;
color: $COL5;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL5;
 }
 }
a.MEC5 { color: $COL16;
&:hover { color: $COL16; }
 }
cite.MEC5{
color: $COL5;
}
/* Alternate:6 */
.MES6 {
background-color: $COL6;
color: $COL7;
 }
/* Alternate:7 */
.MES7 {
background-color: $COL6;
color: $COL7;
padding: 15px 10px;

@media #{$medium-up} {
padding: 50px 60px;

}
 }
.MES7 {
background-color: $COL6;
color: $COL7;
padding: 15px 10px;

@media #{$medium-up} {
padding: 50px 60px;

}
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL7;
 }
 }
cite.MEC7{
color: $COL7;
}
/* Top Strip:8 */
.MES8 {
background-color: $COL36;
color: $COL16;
 }
.MES8 {
background-color: $COL36;
color: $COL16;
 }
a.MEC8 { color: $COL16;
&:hover { color: $COL16; }
 }
cite.MEC8{
color: $COL16;
}
/* Footer Panel:9 */
.MES9 {
background-color: $COL2;
color: $COL16;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
padding: 30px 15px;

border-width: 5px 0 10px 0;
border-style: solid;
border-color: $COL21 transparent $COL4 transparent;
 }
.MES9 {
background-color: $COL2;
color: $COL16;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
padding: 30px 15px;

border-width: 5px 0 10px 0;
border-style: solid;
border-color: $COL21 transparent $COL4 transparent;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL20;
 }
 }
a.MEC9 { color: $COL16;
&:hover { color: $COL16; }
 }
cite.MEC9{
color: $COL16;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* Block 1:10 */
.MES10 {
background-color: $COL22;
color: $COL16;
padding: 20px;

@media #{$medium-up} {
padding: 10px;

}
@media #{$large-up} {
padding: 20px;

}
 }
.MES10 {
background-color: $COL22;
color: $COL16;
padding: 20px;

@media #{$medium-up} {
padding: 10px;

}
@media #{$large-up} {
padding: 20px;

}
h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: $COL16;
 }
 }
a.MEC10 { color: $COL16;
&:hover { color: $COL16; }
 }
cite.MEC10{
color: $COL16;
}
/* Block 2:11 */
.MES11 {
background-color: $COL20;
color: $COL16;
padding: 20px;

@media #{$medium-up} {
padding: 10px;

}
@media #{$large-up} {
padding: 20px;

}
 }
.MES11 {
background-color: $COL20;
color: $COL16;
padding: 20px;

@media #{$medium-up} {
padding: 10px;

}
@media #{$large-up} {
padding: 20px;

}
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: $COL16;
 }
 }
a.MEC11 { color: $COL16;
&:hover { color: $COL16; }
 }
cite.MEC11{
color: $COL16;
}
/* Block 3:12 */
.MES12 {
background-color: $COL23;
color: $COL16;
padding: 20px;

@media #{$medium-up} {
padding: 10px;

}
@media #{$large-up} {
padding: 20px;

}
 }
.MES12 {
background-color: $COL23;
color: $COL16;
padding: 20px;

@media #{$medium-up} {
padding: 10px;

}
@media #{$large-up} {
padding: 20px;

}
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: $COL16;
 }
 }
a.MEC12 { color: $COL16;
&:hover { color: $COL16; }
 }
cite.MEC12{
color: $COL16;
}
/* Slider:13 */
.MES13 {
& .slider-arrow {background-color: $COL24;
color: $COL16;
font-size: 30px;
@media #{$medium-up} {
font-size: 30px;
};
@media #{$large-up} {
font-size: 30px;
};
}& .slider-arrow:hover{background-color: $COL25;
color: $COL16;
} }
/* Main Menu:14 */
nav.me-Menu.MES14 {
& .menu-item.MEC14, & .menu-item.MEC14 > div.MEC14{ & > a.MEC14{color: $COL16;
text-transform: capitalize;
}
 &:hover > a.MEC14{color: $COL16;
}
 }
&.horizontal > .menu-item.MEC14 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC14 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC14 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC14 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}


&.horizontal .menu-item.MEC14:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL20;}}
&.vertical .menu-item.MEC14:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL20;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC14{background-color: $COL20;
 &:hover {background-color: $COL22}
}
& .menu-item.MEC14, & .menu-item.MEC14 > div.MEC14{ & > a.MEC14{color: $COL16;
text-transform: capitalize;
}
 &:hover > a.MEC14{color: $COL16;
}
 }

}
}
 }
/* logo panel:15 */
.MES15 {
background-color: $COL26;
 }
.MES15 {
background-color: $COL26;
 }
/* Light Green:16 */
.MES16 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL23;}
& > hr {border-top-style: dotted;}
 }
/* FAQ:17 */
details.MES17 {
& > summary{background-color: $COL27;
}
color: $COL25;
& > article { color: $COL25;
 }
& > summary{padding: 10px 0;}

& > summary{border-width: 0 0 5px 0;
border-style:solid;
border-color: transparent transparent $COL16 transparent;
}& > summary {& > div  > i{color: $COL16;
font-size: 14px;
}}
& > summary { color: $COL16;
font-size:17.64px;
@media #{$medium-up} {
font-size:23.75px;
}
 }
 }
/* Hollow:19 */
.MES19 {
color: $COL28;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
border-width: 1px;
border-style: solid;
border-color: $COL21;
 }
/* News Block:20 */
.MES20 {
padding: 0 0 10px 0;

border-width: 1px 1px 5px 1px;
border-style: solid;
border-color: $COL29 $COL29 $COL4 $COL29;
 }
.MES20 {
padding: 0 0 10px 0;

border-width: 1px 1px 5px 1px;
border-style: solid;
border-color: $COL29 $COL29 $COL4 $COL29;
 }
/* News Button:21 */
.MES21 {
background-color: $COL2;
color: $COL3;
padding: 10px 15px;

@media #{$medium-up} {
padding: 10px 30px;

}
 }
/* :22 */
.MES22 {
 }
.MES22 {
 }
/* Primary:23 */
.MES23 {
color: $COL3;
 }
.MES23 {
color: $COL3;
h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: $COL3;
 }
 }
cite.MEC23{
color: $COL3;
}
/* News Button 1:24 */
.MES24 {
background-color: $COL30;
color: $COL3;
padding: 10px 15px;

@media #{$medium-up} {
padding: 10px 30px;

}
 }
/* demo2 :25 */
.MES25 {
background-color: $COL33;
 }
.MES25 {
background-color: $COL33;
 }
/* towcolumn:26 */
.MES26 {
background-color: $COL37;
padding: 15px 0;

border-width: 1px;
border-style: solid;
border-color: $COL38;
 }
.MES26 {
background-color: $COL37;
padding: 15px 0;

border-width: 1px;
border-style: solid;
border-color: $COL38;
 }
/* colbox:27 */
.MES27 {
padding: 15px;

 }
.MES27 {
padding: 15px;

 }
/* title-bottom-pd:28 */
.MES28 {
padding: 0 15px 20px 15px;

 }
.MES28 {
padding: 0 15px 20px 15px;

 }
/* button-width:29 */
.MES29 {
 }
/* test:30 */
.MES30 {
 }
/* button-style:31 */
.MES31 {
background-color: $COL39;
&:hover { background-color: $COL42;}
color: $COL40;
&:hover { color: $COL16;}
padding: 5px 20px;

 }
/* pd-bot--lef-rig-15:32 */
.MES32 {
padding: 0 15px 15px 15px;

 }
.MES32 {
padding: 0 15px 15px 15px;

 }
/* case-detail-box:33 */
.MES33 {
background-color: $COL44;
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: $COL43;
 }
.MES33 {
background-color: $COL44;
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: $COL43;
 }
/* blank:34 */
.MES34 {
& .slider-arrow {height: 100%;
font-size: 20px;
@media #{$medium-up} {
font-size: 30px;
};
@media #{$large-up} {
font-size: 50px;
};
} }
